import { Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
// Lazy loading components to only load component when routed
import type { RouteObject } from "react-router";

const AuthLayout = lazy(() => import("src/layouts/Auth"));
// import AuthLayout from "src/layouts/Auth";

const commonRoutes: RouteObject = {
	path: '',
	element: (
		<Suspense fallback={null}>
			<AuthLayout />
		</Suspense>
	),
	children: [
		{ path: '', element: <Navigate to='/client' /> },
	]
}

export default commonRoutes;
