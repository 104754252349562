import { ThemeProvider } from "@material-ui/core/styles";
import staffTheme from '../staff-theme';
import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
// Lazy loading components to only load component when routed

const DashboardLayout = lazy(() => import("src/layouts/Dashboard"));
// import DashboardLayout from "src/layouts/Dashboard";

const Home = lazy(() => import("src/views/common/home"));
// import Home from "src/views/common/home";
const Reports = lazy(() => import("src/views/staff/reports"));
// import Reports from "src/views/staff/reports";
const ReportsParams = lazy(() => import("src/views/staff/reports/params").then(m => ({ default: m.ReportsParams })));
// import { ReportsParams } from "src/views/staff/reports/params";
const ReportsDetails = lazy(() => import("src/views/staff/reports/details").then(m => ({ default: m.ReportsDetails })));
// import { ReportsDetails } from "src/views/staff/reports/details";
const Questions = lazy(() => import("src/views/staff/questions"));
// import Questions from "src/views/staff/questions";
const Forms = lazy(() => import("src/views/staff/forms"));
// import Forms from "src/views/staff/forms";
const CreateForm = lazy(() => import("src/views/staff/forms/create"));
// import CreateForm from "src/views/staff/forms/create"
const FormHistory = lazy(() => import("src/views/staff/forms/components/data-table"));
// import FormHistory from "src/views/staff/forms/components/data-table"
const FormShowAnswer = lazy(() => import("src/views/staff/forms/components/show-answer"));
// import FormShowAnswer from "src/views/staff/forms/components/show-answer"
const Modules = lazy(() => import("src/views/staff/modules"));
// import Modules from "src/views/staff/modules";
const Staffs = lazy(() => import("src/views/staff/staffs"));
// import Staffs from "src/views/staff/staffs";
const Users = lazy(() => import("src/views/staff/users"));
// import Users from "src/views/staff/users";
const UsersParams = lazy(() => import("src/views/staff/users/params"));
// import UsersParams from "src/views/staff/users/params";
const NotificationSettings = lazy(() => import("src/views/staff/notification-settings"));
// import NotificationSettings from "src/views/staff/notification-settings"
const MeasurementValue = lazy(() => import("src/views/staff/notification-settings/measurement-value"));
// import MeasurementValue from "src/views/staff/notification-settings/measurement-value"
const Synchronization = lazy(() => import("src/views/staff/notification-settings/synchronization"));
// import Synchronization from "src/views/staff/notification-settings/synchronization"
const Notifications = lazy(() => import("src/views/staff/notifications"));
// import Notifications from "src/views/staff/notifications"

const Profile = lazy(() => import("src/views/common/profile"));
// import Profile from "src/views/common/profile";

const staffRoutes: RouteObject = {
	path: 'staff',
	element: (
		<ThemeProvider theme={staffTheme}>
			<Suspense fallback={null}>
				<DashboardLayout userType='staff' />
			</Suspense>
		</ThemeProvider>
	),
	children: [
		{
			path: '',
			element: (
				<Suspense fallback={null}>
					<Home />
				</Suspense>
			)
		},
		{
			path: 'reports',
			element: (
				<Suspense fallback={null}>
					<Reports />
				</Suspense>
			)
		},
		{
			path: 'reports/:id',
			element: (
				<Suspense fallback={null}>
					<ReportsParams />
				</Suspense>
			)
		},
		{
			path: 'reports/:id/:uid',
			element: (
				<Suspense fallback={null}>
					<ReportsDetails />
				</Suspense>
			)
		},
		{
			path: 'form',
			element: (
				<Suspense fallback={null}>
					<Forms />
				</Suspense>
			)
		},
		{
			path: 'form/create',
			element: (
				<Suspense fallback={null}>

					<CreateForm />
				</Suspense>
			)
		},
		{
			path: 'form/:id/history',
			element: (
				<Suspense fallback={null}>
					<FormHistory />
				</Suspense>
			)
		},
		{
			path: 'form/:id/history/answer',
			element: (
				<Suspense fallback={null}>

					<FormShowAnswer />
				</Suspense>
			),
		},
		{
			path: 'questions',
			element: (
				<Suspense fallback={null}>

					<Questions />
				</Suspense>
			),
		},
		{
			path: 'devices',
			element: (
				<Suspense fallback={null}>
					<Home />
				</Suspense>
			)
		},
		{
			path: 'modules',
			element: (
				<Suspense fallback={null}>
					<Modules />
				</Suspense>
			)
		},
		{
			path: 'staffs',
			element: (
				<Suspense fallback={null}>
					<Staffs />
				</Suspense>
			)
		},
		{
			path: 'users',
			element: (
				<Suspense fallback={null}>
					<Users />
				</Suspense>
			)
		},
		{
			path: 'users/:id',
			element: (
				<Suspense fallback={null}>
					<UsersParams />
				</Suspense>
			)
		},
		{
			path: 'profile',
			element: (
				<Suspense fallback={null}>
					<Profile />
				</Suspense>
			)
		},
		{
			path: 'notification-settings',
			element: (
				<Suspense fallback={null}>

					<NotificationSettings />
				</Suspense>
			),
		},
		{
			path: 'notification-settings/measurement-value',
			element: (
				<Suspense fallback={null}>
					<MeasurementValue />
				</Suspense>
			)
		},
		{
			path: 'notification-settings/synchronization',
			element: (
				<Suspense fallback={null}>
					<Synchronization />
				</Suspense>
			)
		},
		{
			path: 'notifications',
			element: (
				<Suspense fallback={null}>
					<Notifications />
				</Suspense>
			)
		},
	]
}

export default staffRoutes;
