import authRoutes from "./auth";
import clientRoutes from "./client";
import adminRoutes from "./admin";
import staffRoutes from "./staff";
import commonRoutes from "./common";
import mobileRoutes from "./mobile";

const routeConfig = [
  commonRoutes,
  authRoutes,
  clientRoutes,
  adminRoutes,
  staffRoutes,
  mobileRoutes,
];

export default routeConfig;

// export default function Routes() {
//   const routing = useRoutes([
//     commonRoutes,
//     authRoutes,
//     clientRoutes,
//     adminRoutes,
//     staffRoutes,
//     mobileRoutes,
//   ]);

//   return routing;
// }
