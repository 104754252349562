import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";

// Lazy loading components to only load component when routed

const DashboardLayout = lazy(() => import("src/layouts/Dashboard"));
// import DashboardLayout from "src/layouts/Dashboard";
const Home = lazy(() => import("src/views/client/home"));
// import Home from "src/views/common/home";
const Reports = lazy(() => import("src/views/client/reports").then(m => ({ default: m.Reports })));
// import { Reports } from "src/views/client/reports";
const ReportsResQParams = lazy(() => import("src/views/client/reports/resq-band/params"));
// import ReportsResQParams from "src/views/client/reports/resq-band/params";
const Data = lazy(() => import("src/views/client/data"));
// import Data from "src/views/client/data";
const DataParams = lazy(() => import("src/views/client/data/params"));
// import DataParams from "src/views/client/data/params";
const Entities = lazy(() => import("src/views/client/entities"));
// import Entities from "src/views/client/entities";
const EntitiesParams = lazy(() => import("src/views/client/entities/params"));
// import EntitiesParams from "src/views/client/entities/params";
const Forms = lazy(() => import("src/views/client/forms").then(m => ({ default: m.Forms })));
// import { Forms } from "src/views/client/forms";
const FormDetail = lazy(() => import("src/views/client/forms/form-detail"));
// import FormDetail from "src/views/client/forms/form-detail";
const FormClientShowAnswer = lazy(() => import("src/views/client/forms/show-answer"));
// import FormClientShowAnswer from "src/views/client/forms/show-answer";
const FormDetailAnswer = lazy(() => import("src/views/client/forms/form-answer"));
// import FormDetailAnswer from "src/views/client/forms/form-answer";
const Profile = lazy(() => import("src/views/common/profile"));
// import Profile from "src/views/common/profile";

const Settings = lazy(() => import("src/views/client/settings"));

const clientRoutes: RouteObject = {
	path: 'client',
	element: (
		<Suspense fallback={null}>
			<DashboardLayout userType='client' />
		</Suspense>
	),
	children: [
		{
			path: '',
			element: (
				<Suspense fallback={null}>
					<Home />
				</Suspense>
			),
		},
		{
			path: 'settings',
			element: (
				<Suspense fallback={null}>
					<Settings />
				</Suspense>
			),
		},
		{
			path: 'reports',
			element: (
				<Suspense fallback={null}>
					<Reports />
				</Suspense>
			),
		},
		{
			path: 'reports/resq-band/:type/:id',
			element: (
				<Suspense fallback={null}>
					<ReportsResQParams />
				</Suspense>
			),
		},
		{
			path: 'data',
			element: (
				<Suspense fallback={null}>
					<Data />
				</Suspense>
			)
		},
		{
			path: 'data/:id',
			element: (
				<Suspense fallback={null}>
					<DataParams />
				</Suspense>
			)
		},
		{
			path: 'entities',
			element: (
				<Suspense fallback={null}>
					<Entities />
				</Suspense>
			)
		},
		{
			path: 'forms',
			element: (
				<Suspense fallback={null}>
					<Forms />
				</Suspense>
			)
		},
		{
			path: 'forms/:id/detail',
			element: (
				<Suspense fallback={null}>
					<FormDetail />
				</Suspense>
			)
		},
		{
			path: 'forms/:id/detail/show-answer',
			element: (
				<Suspense fallback={null}>
					<FormClientShowAnswer />
				</Suspense>
			)
		},
		{
			path: 'forms/:id/detail/answer',
			element: (
				<Suspense fallback={null}>
					<FormDetailAnswer />
				</Suspense>
			)
		},
		{
			path: 'entities/:workspace_name',
			element: (
				<Suspense fallback={null}>
					<EntitiesParams />
				</Suspense>
			)
		},
		{
			path: 'profile', element: (
				<Suspense fallback={null}>
					<Profile />
				</Suspense>
			)
		}
	]
}

export default clientRoutes;
