import { ThemeProvider } from "@material-ui/core/styles";
import staffTheme from '../staff-theme';
import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
// Lazy loading components to only load component when routed

const DashboardLayout = lazy(() => import("src/layouts/Dashboard"));
// import DashboardLayout from "src/layouts/Dashboard";
const Home = lazy(() => import("src/views/common/home"));
// import Home from "src/views/common/home";
// import { Reports } from "src/views/client/reports";
// import ReportsResQParams from "src/views/client/reports/resq-band/params";
// import Data from "src/views/client/data";
// import DataParams from "src/views/client/data/params";
const Entities = lazy(() => import("src/views/admin/entities"));
// import Entities from "src/views/admin/entities";
const Users = lazy(() => import("src/views/admin/users"));
// import Users from "src/views/admin/users";
const EntitiesParams = lazy(() => import("src/views/admin/entities/params"));
// import EntitiesParams from "src/views/admin/entities/params";
const CSV = lazy(() => import("src/views/admin/CSVdownload"));
// import CSV from "src/views/admin/CSVdownload";

const Profile = lazy(() => import("src/views/common/profile"));
// import Profile from "src/views/common/profile";

const adminRoutes: RouteObject = {
	path: 'admin',
	element: (
		<ThemeProvider theme={staffTheme}>

			<Suspense fallback={null}>
				<DashboardLayout userType='admin' />
			</Suspense>
		</ThemeProvider>
	),
	children: [
		{
			path: '',
			element: (
				<Suspense fallback={null}>
					<Home />
				</Suspense>
			)
		},
		// { path: 'reports', element: <Reports /> },
		// { path: 'reports/resq-band/:type/:id', element: <ReportsResQParams /> },
		// { path: 'data', element: <Data /> },
		{
			path: 'users',
			element: (
				<Suspense fallback={null}>
					<Users />
				</Suspense>
			),
		},
		{
			path: 'CSVdownload',
			element: (
				<Suspense fallback={null}>
					<CSV />
				</Suspense>
			),
		},
		{
			path: 'entities',
			element: (
				<Suspense fallback={null}>
					<Entities />
				</Suspense>
			),
		},
		{
			path: 'entities/:workspace_name',
			element: (
				<Suspense fallback={null}>
					<EntitiesParams />
				</Suspense>
			),
		},
		{
			path: 'profile',
			element: (
				<Suspense fallback={null}>
					<Profile />
				</Suspense>
			)
		}
	]
}

export default adminRoutes;
