import { createTheme } from '@material-ui/core/styles'

import palette from './palette';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme(
	{
		shadows,
		typography,
		palette: palette,
		components: {
			MuiInputBase: {
				styleOverrides: {
					root: {
						'@media (max-width: 480px)': {
							fontSize: "16px",
						}
					}
				}
			}
		}
	},
);

export default theme;
