import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  staffReportReducer,
  StaffReportStateType,
} from "./staff-report/reducers";
import { userReportReducer, UserReportStateType } from "./user-report/reducers";

export interface IReduxState {
  staffReport: StaffReportStateType;
  userReport: UserReportStateType;
}

export const rootReducer = combineReducers({
  staffReport: staffReportReducer,
  userReport: userReportReducer,
});

export const store = createStore(rootReducer, composeWithDevTools());
