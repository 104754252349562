import { createTheme } from '@material-ui/core/styles'

import palette from './palette';
import shadows from './shadows';
import typography from './typography';

const staffTheme = createTheme(
	{
		shadows,
		typography,
		palette: palette,
	},
);

export default staffTheme;
