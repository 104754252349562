import { ThemeProvider } from '@material-ui/core/styles';
import staffTheme from '../staff-theme';
import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";

// Lazy loading components to only load component when routed

const AuthLayout = lazy(() => import("src/layouts/Auth"));
// import AuthLayout from "src/layouts/Auth";
const ClientSignIn = lazy(() => import("src/views/auth/client/sign-in"));
// import ClientSignIn from "src/views/auth/client/sign-in";
const ForgotPassword = lazy(() => import("src/views/auth/forgot-password"));
// import ForgotPassword from "src/views/auth/forgot-password";
const SignUp = lazy(() => import("src/views/auth/sign-up"));
// import SignUp from "src/views/auth/sign-up";
const StaffEntitySelect = lazy(() => import("src/views/auth/staff/entity-select"));
// import StaffEntitySelect from "src/views/auth/staff/entity-select";
const StaffSignIn = lazy(() => import("src/views/auth/staff/sign-in"));
// import StaffSignIn from "src/views/auth/staff/sign-in";
const AdminSignIn = lazy(() => import("src/views/auth/admin/sign-in"));
// import AdminSignIn from "src/views/auth/admin/sign-in";

const authRoutes: RouteObject = {
	path: 'auth',
	element: (
		<Suspense fallback={null}>
			<AuthLayout />
		</Suspense>
	),
	children: [
		{
			path: 'client/sign-in',
			element: (
				<Suspense fallback={null}>
					<ClientSignIn />
				</Suspense>

			),
		},
		{
			path: 'admin/sign-in',
			element: (
				<ThemeProvider theme={staffTheme}>
					<Suspense fallback={null}>
						<AdminSignIn />
					</Suspense>
				</ThemeProvider>
			),
		},
		{
			path: 'forgot-password',
			element: (
				<Suspense fallback={null}>
					<ForgotPassword />
				</Suspense>
			),
		},
		{
			path: 'sign-up',
			element: (
				<Suspense fallback={null}>
					<SignUp />
				</Suspense>
			),
		},
		{
			path: 'staff/entity-select',
			element: (
				<ThemeProvider theme={staffTheme}>
					<Suspense fallback={null}>
						<StaffEntitySelect />
					</Suspense>
				</ThemeProvider>
			),
		},
		{
			path: 'staff/sign-in',
			element: (
				<ThemeProvider theme={staffTheme}>
					<Suspense fallback={null}>
						<StaffSignIn />
					</Suspense>
				</ThemeProvider>
			),
		},
	]
}

export default authRoutes;
