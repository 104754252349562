import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from 'src/i18n/en/translation.json';
import translation_jp from 'src/i18n/jp/translation.json';
import UniversalCookie from 'universal-cookie';


const cookie = new UniversalCookie();

var locale = navigator.language?.split('-')[0]=='en'?'en':'jp'

console.log('Navigator language: ', locale);
if (cookie.get('userLanguage') == 'jp'){
	locale = 'jp';
}
else if(cookie.get('userLanguage') == 'en'){
	locale = 'en';
}

i18next
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV !== 'production',
		whitelist: ['jp', 'en'],
		nonExplicitWhitelist: true,
		fallbackLng: locale,
		lng: locale,
		resources: {
			jp: {
				translation: translation_jp
			},
			en: {
				translation: translation_en,
			},
		},
		react: {
			useSuspense: true
		},
		interpolation: { escapeValue: false }
	})

export default i18next;