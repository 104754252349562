import { TInterval } from "./reducers";

export enum UserReportActions {
  SetInterval = "SET_INTERVAL",
  SetStartDate = "SET_START_DATE",
}

export interface IUserReportActions {
  type: UserReportActions;
  payload: any;
}

export function setInterval(value: TInterval): any {
  return {
    type: UserReportActions.SetInterval,
    payload: value,
  };
}

export function setStartDate(value: any): any {
  return {
    type: UserReportActions.SetStartDate,
    payload: value,
  };
}
