import type { RouteObject } from "react-router";
import { lazy, Suspense } from "react";
// Lazy loading components to only load component when routed

const MobileLayout = lazy(() => import("src/layouts/Mobile"));
// import MobileLayout from "src/layouts/Mobile";
const ReportsParams = lazy(() => import("src/views/mobile/reports/params"));
// import ReportsParams from "src/views/mobile/reports/params";

const mobileRoutes: RouteObject = {
	path: 'mobile',
	element: (
		<Suspense fallback={null}>
			<MobileLayout />
		</Suspense>
	),
	children: [
		{
			path: 'reports/resq_band',
			element: (
				<Suspense fallback={null}>
					<ReportsParams />
				</Suspense>
			),
		},
	]
}

export default mobileRoutes;
